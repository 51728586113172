import React, {useEffect, useMemo, useState} from 'react';
import {Button, Col, Input, Row, Space} from "antd";
import axios from "axios";

import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {STREAM_SERVER} from "./Constants";

function parseQuery(queryString) {
    console.log('qert neee')
    if (queryString?.length)
        return JSON.parse('{"' + queryString.replace('?&', '').replace('?', '').replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
            return key === "" ? value : decodeURIComponent(value)
        });
    return {}
}

const PM = () => {
    const {topic, subTopic} = useParams();
    const searchParamString = window.location.search;
    const params = parseQuery(searchParamString);
    const {page, query, ...remainQuery} = params
    const filter = searchParamString?.length && (new URLSearchParams(remainQuery).toString()).replaceAll("&", "$").trim();
    let target = topic
    subTopic && (target = `${topic}/${subTopic}`)
    const {search} = useLocation();
    const navigate = useNavigate();
    const searchParams = useMemo(() => new URLSearchParams(search), [search]);
    const [searchString, setSearchString] = useState(query)
    useEffect(() => {
        query && setSearchString(searchString)
        handleSearch({query, page, target, filter})
    }, [query, page, target]);


    const [result, setResult] = useState();
    useEffect(() => {
        setTimeout(() => {
            window.reloadPlugin()
        }, 2000)
    }, [result]);

    const [errorMessage, setErrorMessage] = useState();

    async function handleSearch({query, page, target, filter}) {
        try {
            console.log('searchne')
            let url = !target && query ? `${STREAM_SERVER}/search-pm?query=${query}` : `${STREAM_SERVER}/home-pm?target=${target}`;
            page && (url = `${url}&page=${page}`)
            console.log(url, target, !target && query)
            const response = await axios.get(filter ? `${url}&filter=${filter}` : url);
            setResult(response.data)
            setSearchString('')
            setErrorMessage(null)
        } catch (e) {
            setResult(null)
            setErrorMessage(e.message)
        }
    }

    function sendSearch() {
        searchParams.set("query", searchString);
        searchParams.set("page", 1);
        searchParams.delete("target");
        const s = `/?${searchParams.toString()}`;
        console.log(s)
        navigate(s);
    }

    return (
        <div style={{
            backgroundColor: '#4f4e52',
            color: 'white',

        }}>
            <Row justify={"space-between"} style={{backgroundColor: 'black', padding: 8}} align={"middle"}>
                <Row style={{paddingLeft: 10, paddingRight: 10, marginTop: 0, cursor: "pointer",}}
                     onClick={async () => {
                         navigate('/')
                     }} align={"middle"}><img src="/logo.png" alt="" width={40}/><span
                    style={{marginLeft: 9, fontSize: 28, fontWeight: 800}}>BPM</span></Row>
                <Input placeholder="type to search" style={{width: 150}} value={searchString}
                       onChange={e => setSearchString(e.target.value)} onPressEnter={async () => {
                    sendSearch();
                }}/>
            </Row>
            <Row justify={"center"} style={{marginBottom: 100}}>
                <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                    <Row style={{flexDirection: 'column', marginBottom: 32}} justify={"center"} align={"middle"}>


                        {errorMessage && <div><h3 style={{color: 'red'}}>{errorMessage}</h3></div>}
                        {result &&
                            <div dangerouslySetInnerHTML={{__html: result}} className={query ? 'row' : ''}
                                 style={{width: '100%', maxWidth: 1400}}>

                            </div>}

                    </Row>

                </Col>
            </Row>


        </div>
    );
};

export default PM;
