import React, {useEffect, useState} from 'react';
import {Button, Col, Descriptions, Flex, Input, Row, Space, Spin} from "antd";
import axios from "axios";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {ProCard} from "@ant-design/pro-components";
import {STREAM_SERVER} from "./Constants";

const Movie = () => {
    const {movieId, episode} = useParams();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (episode) {
            let playerURl = `${STREAM_SERVER}/play-pm?path=${movieId}/${episode}`;
            console.log(playerURl)
            window.location = playerURl
        } else {
            movieId && hackPhimmoi(movieId)
        }

    }, [movieId, episode]);
    const navigate = useNavigate();
    const [movieInfos, setMovieInfos] = useState({});

    const [errorMessage, setErrorMessage] = useState();

    async function hackPhimmoi() {
        setLoading(true)
        try {
            const response = await axios.get(`${STREAM_SERVER}/get-pm?movieId=${movieId}`);
            setMovieInfos(response.data)
            setErrorMessage(null)
        } catch (e) {
            setMovieInfos({})
            setErrorMessage(e.message)
        }
        setLoading(false)
    }

    const [searchString, setSearchString] = useState()
    return (
        <ProCard ghost style={{
            backgroundColor: '#4f4e52',
            color: 'white',
            height: '100vh',
            overflow: 'scroll',
        }}>
            <Flex vertical align={"center"} style={{marginBottom: 100}}>
                <Row justify={"space-between"} style={{backgroundColor: 'black', padding: 8, width: '100%'}}
                     align={"middle"}>
                    <Row style={{paddingLeft: 10, paddingRight: 10, marginTop: 0, cursor: "pointer",}}
                         onClick={async () => {
                             navigate('/')
                         }} align={"middle"}><img src="/logo.png" alt="" width={40}/><span
                        style={{marginLeft: 9, fontSize: 28, fontWeight: 800}}>BPM</span></Row>
                    <Input placeholder="type to search" style={{width: 150}} value={searchString}
                           onChange={e => setSearchString(e.target.value)} onPressEnter={async () => {
                        navigate(`/?search=${searchString}`)
                    }}/>
                </Row>
                <Row style={{flexDirection: 'column', marginBottom: 32,padding:16,backgroundColor: 'black',}} justify={"center"} align={"middle"}>
                    <Spin spinning={loading} tip={"We're hacking"} style={{height: '100vh'}}>

                        {errorMessage &&
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}><h3
                                style={{color: 'red'}}>{errorMessage}</h3> <Button type={"primary"} danger
                                                                                   onClick={() => {
                                                                                       hackPhimmoi();
                                                                                   }}>Reload</Button>

                                <Row style={{marginTop: 16}}>
                                    <Button type="link" danger onClick={async () => {
                                        navigate('/')
                                    }}>Home</Button>
                                    <Button type="link" onClick={async () => {
                                        window.open(`https://phimmoiiii.com/phim${movieId}`, '_blank')
                                    }}>Phimmoi</Button>
                                </Row>
                            </div>}
                        {movieInfos?.episodes &&
                            <Row style={{flexDirection: "column"}} justify={"center"} align={"middle"}>
                                <h1 style={{textAlign: "center"}}>
                                    <span className={"title-1"}>{movieInfos.title1}</span>
                                    <span className={"title-2"}>{movieInfos.title2}</span>
                                    <span className={"title-2"}>{movieInfos.year}</span>
                                </h1>
                                <Row justify={"space-around"} gutter={[16, 16]} align={"middle"} style={{backgroundColor: '#332727'}}>
                                    <Col xs={24} sm={24} md={24} lg={12} xxl={12} style={{textAlign: "center",paddingLeft:0}}>
                                        <img src={movieInfos.imgSrc}  alt="movieimage"/>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={12} xxl={12}
                                         style={{display: "flex", justifyContent: 'center',}}>
                                        <Descriptions bordered labelStyle={{color: "wheat"}}
                                                      contentStyle={{color: "white"}}
                                                      style={{
                                                          backgroundColor: '#332727',
                                                          width: 300,
                                                          height: '100%'
                                                      }}
                                                      size={"small"} column={1}
                                                      items={Object.entries(movieInfos.metaInfo).map(([key, value]) => ({
                                                          label: key,
                                                          children: value
                                                      }))}/>
                                    </Col>

                                </Row>
                                <Row justify={"center"}>
                                    <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
                                        <p style={{
                                            textAlign: "justify",
                                            marginTop: 16, maxWidth: 610
                                        }}>{movieInfos.description}</p>
                                    </Col>
                                </Row>

                                <Flex wrap="wrap" gap="small">
                                    {movieInfos.episodes?.map(e => {
                                        let url = `${e.link}`;
                                        return <Button type={'dashed'} style={{marginRight: 8}}
                                                       onClick={() => window.open(url, "_blank")}>{e.name}</Button>
                                    })}

                                </Flex>
                            </Row>
                        }
                    </Spin>
                </Row>
                <div style={{height: 140}}></div>
            </Flex>


        </ProCard>
    );
};

export default Movie;
